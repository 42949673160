<template>
	<div>

	</div>
</template>

<script>
import {ElMessage} from "element-plus";
import {mapGetters} from "vuex";
import VXETable from "vxe-table";

export default {
	name: "",
	inject: ['reload'],
	data() {
		return {}
	},
	created() {
		this.goToCarGps()
	},
	computed: {
		...mapGetters(['GET_TOKEN_CAR'])
	},
	methods: {
		goToCarGps(){
			console.log(this.GET_TOKEN_CAR)
			let url = 'https://vps.cr12cz.cn/login/ssologin?loginName=' + this.GET_TOKEN_CAR
			window.open(url, '_blank');
			this.$router.push("/manage")
		}
	}
}
</script>

<style>

</style>